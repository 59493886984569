import axios from "axios";
import {SetButtonStatus, SetProcessStatus} from "../../features/reducers/global";
import APP from "../app";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const httpClient = axios.create({
    baseURL: APP.baseURLBE,
});

httpClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    function (response) {
        return response;
    },
    httpResponse
);

function httpResponse(error) {
    const originalRequest = error.config;

    if (!error.response) {
        console.log("Please check your internet connection.");
        // window.location.href = `/login`
        return Promise.reject(error);
    }

    // console.log(error)
    if (error.response?.status === 401 && originalRequest.url === `/user/v2/refresh`) {
        window.location.href = "/login";
        return Promise.reject(error);
    }

    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    let model = {
        accessToken: accessToken,
        refreshToken: refreshToken,
    };


    if (error.response?.status === 401 && !originalRequest._retry && originalRequest.url !== `/user/v2/signin`) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject});
            }).then(() => {
                originalRequest.headers[
                    "Authorization"
                    ] = `Bearer ${model.accessToken}`;
                return axios(originalRequest);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {

            model = {
                accessToken: localStorage.getItem("accessToken"),
                refreshToken: localStorage.getItem("refreshToken"),
            };

            axios.post(`${APP.baseURLBE}user/v2/refresh`, model, {
                headers: {Authorization: `Bearer ${model.accessToken}`},
            }).then(resp => {
                const token = {
                    accessToken: resp.data.accessToken,
                    refreshToken: resp.data.refreshToken,
                }

                localStorage.setItem("accessToken", token.accessToken);
                localStorage.setItem("refreshToken", token.refreshToken);

                httpClient.defaults.headers.common["Authorization"] = `Bearer ${token.accessToken}`;
                originalRequest.headers["Authorization"] = `Bearer ${token.accessToken}`;
                resolve(axios(originalRequest));
            }).catch((err) => {
                console.log("refresh err:", err);

                processQueue(err, null);

                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");

                window.location.href = "/login";
                reject({status: error.response.status, error: error});
            }).finally(() => {
                isRefreshing = false;
            });
        });
    }

    return Promise.reject(error);
}


export default httpClient

export const httpClientOffice = axios.create({
    baseURL: APP.baseURLBE,
});


export const httpRequestFunc = async (baseThunk, callback, successTopup = true, loadingScreen = true, message) => {

    if (loadingScreen)
        baseThunk.dispatch(SetProcessStatus({status: 'loading'}))

    try {
        const response = await callback
        if (response?.status === 200) {
            if (successTopup) {
                setTimeout(() => {
                    let popupStatus = {status: 'success'}
                    if (typeof message !== `undefined`) {
                        popupStatus = {...popupStatus, message: message}
                    }
                    // console.log(popupStatus)
                    baseThunk.dispatch(SetProcessStatus(popupStatus))
                }, 500)
            } else {
                baseThunk.dispatch(SetProcessStatus(null))
            }
            return response
        }

        switch (response?.status) {
            case 400:
                baseThunk.dispatch(SetProcessStatus({status: 'error', message: `ข้อมูลไม่ถูกต้อง`}))
                break
        }

    } catch (e) {
        // baseThunk.dispatch(SetProcessStatus({status: 'error'}))
        const data = e?.response?.data
        if (e?.response.status == 403 && data?.message == 'TOKEN_MISSING') {
            return
        }

        switch (e?.response.status) {
            case 400:
                baseThunk.dispatch(SetProcessStatus({status: 'warning', message: `ข้อมูลไม่ถูกต้อง`}))
                break
            default :
                let popupStatus = {status: 'error'}
                if (typeof message !== `undefined`) {
                    popupStatus = {...popupStatus, message: message}
                }
                baseThunk.dispatch(SetProcessStatus(popupStatus))
        }
        // notificationErrorMessage(e)
        throw baseThunk.rejectWithValue(data?.message)
    }
}

export const httpRequestFuncCustomHandle = async (baseThunk, callback, {
    successTopup,
    loadingScreen,
    successMessage,
    errMessage
}) => {

    // if (typeof loadingScreen === `undefined` || loadingScreen)
    //     baseThunk.dispatch(SetProcessStatus({status: 'loading'}))

    baseThunk.dispatch(SetButtonStatus("loading"))

    try {
        const response = await callback
        if (response?.status === 200) {

            if (typeof successTopup !== `undefined` && successTopup) {
                setTimeout(() => {
                    let popupStatus = {status: 'success'}
                    if (typeof successMessage !== `undefined`) {
                        popupStatus = {...popupStatus, message: successMessage}
                    }
                    baseThunk.dispatch(SetProcessStatus(popupStatus))
                }, 500)
            } else {
                baseThunk.dispatch(SetProcessStatus(null))
            }
            baseThunk.dispatch(SetButtonStatus(null))
            return response
        }

        switch (response?.status) {
            case 400:
                baseThunk.dispatch(SetProcessStatus({status: 'error', message: `ข้อมูลไม่ถูกต้อง`}))
                break
        }
        baseThunk.dispatch(SetButtonStatus(null))

    } catch (e) {
        baseThunk.dispatch(SetButtonStatus(null))
        // baseThunk.dispatch(SetProcessStatus({status: 'error'}))
        const data = e?.response?.data
        if (e?.response.status == 403 && data?.message == 'TOKEN_MISSING') {
            return
        }

        switch (e?.response.status) {
            case 400:
                baseThunk.dispatch(SetProcessStatus({status: 'warning', message: `ข้อมูลไม่ถูกต้อง`}))
                break
            default :
                let popupStatus = {status: 'warning'}
                if (typeof errMessage !== `undefined`) {
                    popupStatus = {...popupStatus, message: errMessage}
                }
                baseThunk.dispatch(SetProcessStatus(popupStatus))
        }
        // notificationErrorMessage(e)
        throw baseThunk.rejectWithValue(data?.message)
    }
}


export const httpObjQueryString = (data) => {
    if (typeof data != `undefined`) {
        let dt = data
        return Object.entries(dt).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
    }
    return ``
}
