import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import userReducer from "./reducers/user/userSlice";
import gameReducer from "./reducers/game/gameSlice";
import globalReducer from "./reducers/global";

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})
export const store = configureStore({
    reducer: {
        user: userReducer,
        game: gameReducer,
        globals: globalReducer,
    },
    middleware: customizedMiddleware,
})

export default store;
