import {toast} from "react-toastify";


export const NotificationMessage = (message, type = `success`) => {
    const options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: type,
    }
    return toast(message, options)
}
