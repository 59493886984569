import {createSlice} from "@reduxjs/toolkit";
import {GameListsRecommendThunk, GameListsThunk} from "./gameThunk";
import GameBrands from "../../../components/images/GameBrands";


const gameSlice = createSlice({
    name: `game`,
    initialState: {
        providers: {
            slot: [{name: `PG Slot`, provider: `PGSOFT`, image: GameBrands.pg}, {
                name: `Live22`,
                provider: `LIVE22`,
                image: GameBrands.live22
            }, {
                name: `Slot XO`,
                provider: `SLOTXO`,
                image: GameBrands.slotxo
            }, {name: `xxx`, image: GameBrands.soon}, {name: `xxx`, image: GameBrands.soon}, {
                name: `xxx`,
                image: GameBrands.soon
            },],
            casino: [{name: `Sexy Gaming`, provider: `SEXY`, image: GameBrands.sexy}, {
                name: `Pretty Gaming`,
                provider: `PRETTY`,
                image: GameBrands.pretty
            }, {name: `Dream Gaming`, provider: `DREAM`, image: GameBrands.dream}, {
                name: `Sa Gaming`,
                provider: `SAGAME`,
                image: GameBrands.sa
            }, {
                name: `xxx`,
                image: GameBrands.soon
            }, {name: `xxx`, image: GameBrands.soon},],
            lotto: [{name: `xxx`, image: GameBrands.soon}, {name: `xxx`, image: GameBrands.soon}, {
                name: `xxx`,
                image: GameBrands.soon
            }, {name: `xxx`, image: GameBrands.soon}, {name: `xxx`, image: GameBrands.soon}, {
                name: `xxx`,
                image: GameBrands.soon
            },],
            sport: [{name: `UFA Bet`, image: GameBrands.ufa}, {name: `LSM99`, image: GameBrands.lsm}, {
                name: `Muay Step`,
                image: GameBrands.muaystep
            }, {name: `xxx`, image: GameBrands.soon}, {name: `xxx`, image: GameBrands.soon}, {
                name: `xxx`,
                image: GameBrands.soon
            },],
        },
        games: null,
        openGame: null,
        gamesRecommend: [],
    },
    reducers: {
        ResetGame: (state, {payload}) => {
            return {...state, games: null}
        },
        SetOpenGame: (state, {payload}) => {
            return {...state, openGame: payload}
        },
        ResetGameRec: (state, {payload}) => {
            return {...state, gamesRecommend: []}
        },
    },
    extraReducers: {
        [GameListsThunk.fulfilled]: (state, {payload}) => {
            return {...state, games: payload}
        },
        [GameListsRecommendThunk.fulfilled]: (state, {payload}) => {
            return {...state, gamesRecommend: [...state.gamesRecommend, ...payload.games]}
        }
    },
})

export const getGames = (state) => state.game.games
export const getGamesRec = (state) => state.game.gamesRecommend
export const getOpenGame = (state) => state.game.openGame
export const getGameProviders = (state) => state.game.providers
export const {
    ResetGame,
    ResetGameRec,
    SetOpenGame
} = gameSlice.actions
export default gameSlice.reducer
