import httpClient, {httpClientOffice, httpObjQueryString} from "../config/httpClient";

export const GameGetLists = (filter) => {
    return httpClient.get(`/game?${httpObjQueryString(filter)}`)
}


export const GameLunch = (productId, gameCode, mobile) => {
    const data = {
        'productId': productId,
        'gameCode': gameCode,
        'isMobile': mobile
    }
    return httpClient.post('/game/v2/launch', data)
}

