import {httpRequestFunc, httpRequestFuncCustomHandle} from "../../../common/config/httpClient";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    UserAgentBank, UserBalance, UserBetHistory,
    UserChangePassword, UserCheckAffSetting, UserCheckPhone, UserCheckRef, UserChilds, UserOTPRequest, UserOTPVerify,
    UserProfile, UserPromotion, UserSetAffSetting,
    UserSignIn,
    UserTogglePromotion, UserTopupHistory, UserUpdateBankAccountProfile,
    UserUpdateProfile, UserWithdraw, UserWithdrawAff
} from "../../../common/api/userApi";
import {SetProcessStatus, SetRedirectUrl} from "../global";
import {NotificationMessage} from "../../../common/functions/notification";


export const UserSignInThunk = createAsyncThunk(
    'user/UserSignInThunk',
    async ({username, password, prefix}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserSignIn(username, password, prefix), false)
            // console.log(response.data)
            // alert(1)
            return response.data
        } catch (e) {
        }
    }
)
export const UserOTPRequestThunk = createAsyncThunk(
    'user/UserOTPRequest',
    async ({username, password, phone, prefix, promotion}, baseThunk) => {

        const ref = localStorage.getItem("ref")
        let response = await httpRequestFuncCustomHandle(baseThunk, UserCheckPhone(phone), {
            successTopup: false,
            loadingScreen: true,
            errMessage: `ไม่สามารถตรวจสอบหมายเลขโทรศัพท์ได้`
        })

        if (response.data === false || response.data === `false`) {

            let hasRef = false, checkRef = false
            if (ref !== `` && ref !== null) {
                hasRef = true
                response = await httpRequestFuncCustomHandle(baseThunk, UserCheckRef(ref), {
                    successTopup: false,
                    loadingScreen: true,
                    errMessage: `ไม่สามารถรหัสแนะนำได้`
                })
                if (response.data === true || response.data === `true`) {
                    checkRef = true
                }
            }

            if (!hasRef || (hasRef && checkRef)) {
                try {
                    response = await httpRequestFunc(baseThunk, UserOTPRequest(username, password, phone, prefix, promotion), false, true, `ผิดพลาด หมายเลขโทรศัทพ์ของคุณอาจถูกใช้งานแล้ว`)
                    // console.log(response.data)
                    localStorage.setItem("otpCode", response.data?.code)
                    localStorage.setItem("otpPhone", response.data?.phone)
                    localStorage.setItem("otpPassword", password)
                    localStorage.setItem("otpPromotion", promotion)
                    localStorage.setItem("otpLeft", 60)

                    baseThunk.dispatch(SetRedirectUrl(`/register/otp`))
                    return response.data
                } catch (e) {
                }
            } else {
                // NotificationMessage(`รหัสแนะนำไม่ถูกต้อง`, `warning`)
                baseThunk.dispatch(SetProcessStatus({message: `รหัสแนะนำไม่ถูกต้อง`, status: `warning`}))
            }
        } else {
            baseThunk.dispatch(SetProcessStatus({message: `หมายเลขโทรศัพท์ถูกใช้งานแล้ว`, status: `warning`}))
            // NotificationMessage(`หมายเลขโทรศัพท์ถูกใช้งานแล้ว`, `warning`)
        }

    }
)

export const UserOTPVerifyThunk = createAsyncThunk(
    'user/UserOTPVerifyThunk',
    async ({otp, prefix}, baseThunk) => {
        try {
            let promotion = localStorage.getItem("otpPromotion")
            promotion = (promotion === true || promotion === `true`) ? true : false
            const data = {
                otp: otp,
                phone: localStorage.getItem("otpPhone"),
                code: localStorage.getItem("otpCode"),
                username: localStorage.getItem("otpPhone"),
                password: localStorage.getItem("otpPassword"),
                promotion: promotion,
            }
            const response = await httpRequestFunc(baseThunk, UserOTPVerify(data.phone, data.code, otp, prefix, data.username, data.password, data.promotion), false, true, `ยืนยัน OTP ไม่ถูกต้อง`)
            if (response.status === 200) {

                localStorage.removeItem("otpCode")
                localStorage.removeItem("otpPhone")
                localStorage.removeItem("otpPassword")
                localStorage.removeItem("otpPromotion")
                localStorage.removeItem("otpLeft")

                baseThunk.dispatch(SetRedirectUrl(`/dashboard`))
                return response.data
            } else {

            }

        } catch (e) {
        }
    }
)

export const UserProfileThunk = createAsyncThunk(
    'user/UserProfileThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserProfile(), false, data?.loadingScreen ?? true)
            return response.data
        } catch (e) {
        }

    }
)
export const UserBalanceThunk = createAsyncThunk(
    'user/UserBalanceThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserBalance(), false, data?.loadingScreen ?? true)
            return response.data
        } catch (e) {
        }

    }
)

export const UserUpdateThunk = createAsyncThunk(
    'user/UserUpdateThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserUpdateProfile(data))
            if (response.status === 200) {
                baseThunk.dispatch(UserProfileThunk())
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserUpdateBankAccountThunk = createAsyncThunk(
    'user/UserUpdateBankAccountThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserUpdateBankAccountProfile(data))
            if (response.status === 200) {
                baseThunk.dispatch(UserProfileThunk())
                return response.data
            }
        } catch (e) {
        }
    }
)


export const UserTogglePromotionThunk = createAsyncThunk(
    'user/UserTogglePromotionThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserTogglePromotion())
            if (response.status === 200) {
                baseThunk.dispatch(UserProfileThunk())
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserChangePasswordThunk = createAsyncThunk(
    'user/UserChangePasswordThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserChangePassword(data))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserCheckAffSettingThunk = createAsyncThunk(
    'user/UserCheckAffSettingThunk',
    async ({prefix, username, loadingScreen}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserCheckAffSetting(prefix, username), false, loadingScreen)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserChildsThunk = createAsyncThunk(
    'user/UserChildsThunk',
    async ({prefix, page, pageSize, loadingScreen}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserChilds(prefix, page, pageSize), false, loadingScreen)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserSetAffSettingThunk = createAsyncThunk(
    'user/UserSetAffSettingThunk',
    async ({prefix, type, username}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserSetAffSetting(type, prefix, username))
            if (response.status === 200) {
                // baseThunk.dispatch(UserProfileThunk())
                baseThunk.dispatch(UserCheckAffSettingThunk({loadingScreen: true}))
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserWithdrawThunk = createAsyncThunk(
    'user/UserWithdrawThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserWithdraw(data), true, true, `แจ้งถอนเงินแล้ว`)
            if (response.status === 200) {
                baseThunk.dispatch(UserProfileThunk({loadingScreen: false}))
                return response.data
            }
        } catch (e) {
        }
    }
)

export const UserWithdrawAffThunk = createAsyncThunk(
    'user/UserWithdrawAffThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserWithdrawAff(data))
            if (response.status === 200) {
                baseThunk.dispatch(UserProfileThunk({loadingScreen: false}))
                baseThunk.dispatch(UserCheckAffSettingThunk({loadingScreen: false}))
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserAgentBankThunk = createAsyncThunk(
    'user/UserAgentBankThunk',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserAgentBank(), false, false)
            if (response.status === 200) {
                return response.data[0]
            }
        } catch (e) {
        }
    }
)
export const UserBetHistoryThunk = createAsyncThunk(
    'user/UserBetHistory',
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserBetHistory(filter), false, true)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)


export const UserTopupHistoryThunk = createAsyncThunk(
    'user/UserTopupHistoryThunk',
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserTopupHistory(filter), false, true)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserCheckPhoneThunk = createAsyncThunk(
    'user/UserCheckPhoneThunk',
    async (phone, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserCheckPhone(phone), false, false)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)
export const UserPromotionThunk = createAsyncThunk(
    'user/UserPromotionThunk',
    async (prefix, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, UserPromotion(prefix), false, false)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)
