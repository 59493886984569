import logoPhenix295 from '../../assets/images/partners/phenix295/logo.png'
import logoPhenix295Light from '../../assets/images/partners/phenix295/logo-light.png'
import logoPhenix295QR from '../../assets/images/partners/phenix295/qr.jpg'
import logoPhenixCover from '../../assets/images/partners/phenix295/cover.png'

/*UFO*/
import logoUfo98 from '../../assets/images/logos/ufo98.png'

/*VIPACLUB*/
import logoVipaclub from '../../assets/images/partners/vipaclub/logo.png'
import logoVipaclubLight from '../../assets/images/partners/vipaclub/logo-light.png'
import logoVipaclubQR from '../../assets/images/partners/vipaclub/qr.jpg'
import logoVipaclubCover from '../../assets/images/partners/vipaclub/cover.png'



const Logos = {
    phenix: {
        normal: logoPhenix295,
        light: logoPhenix295Light,
        qrcode: logoPhenix295QR,
        cover: logoPhenixCover
    },
    ufo98: {
        normal: logoUfo98,
        light: logoPhenix295Light,
        qrcode: logoPhenix295QR,
        cover: logoPhenixCover
    },
    vipaclub: {
        normal: logoVipaclub,
        light: logoVipaclubLight,
        qrcode: logoVipaclubQR,
        cover: logoVipaclubCover
    },
}

export default Logos
