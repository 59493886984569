import {createSlice} from "@reduxjs/toolkit";

const globalSlice = createSlice({
    name: `globals`,
    initialState: {
        loading: false,
        redirect: false,
        redirectUrl: false,
        activeFooterMenu: null,
        processStatus: null,
        buttonStatus: null
    },
    reducers: {
        SetActiveFooterMenu: (state, {payload}) => {
            return {...state, activeFooterMenu: payload}
        },
        SetProcessStatus: (state, {payload}) => {
            return {...state, processStatus: payload}
        },
        SetButtonStatus: (state, {payload}) => {
            return {...state, buttonStatus: payload}
        },
        SetRedirectUrl: (state, {payload}) => {
            return {...state, redirectUrl: payload, redirect: true}
        }

    }
})
export const footerActiveMenu = (state) => state.globals.activeFooterMenu
export const processStatus = (state) => state.globals.processStatus
export const buttonStatus = (state) => state.globals.buttonStatus
export const getRedirectUrl = (state) => state.globals.redirectUrl
export const {SetActiveFooterMenu, SetProcessStatus, SetRedirectUrl, SetButtonStatus} = globalSlice.actions
export default globalSlice.reducer
