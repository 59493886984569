import deposit from "../../assets/images/icons/deposit.svg";
import withdraw from "../../assets/images/icons/withdraw.svg";
import affiliate from "../../assets/images/icons/affiliate.svg";
import topup from "../../assets/images/icons/history-topup.svg";
import bet from "../../assets/images/icons/history-bet.svg";
import account from "../../assets/images/icons/contact.svg";
import password from "../../assets/images/icons/password.svg";
import contact from "../../assets/images/icons/contact.svg";
import slot from "../../assets/images/icons/slot.svg";
import casino from "../../assets/images/icons/casino.svg";
import lotto from "../../assets/images/icons/lotto.svg";
import sport from "../../assets/images/icons/sport.svg";
import user from "../../assets/images/icons/user.png";
import text from "../../assets/images/icons/text.png";
import wallet from "../../assets/images/icons/wallet.png";
import click from "../../assets/images/icons/click.png";
import network from "../../assets/images/icons/network.png";


const Icons = {
    deposit,
    withdraw,
    affiliate,
    topup,
    bet,
    account,
    password,
    contact,
    slot,
    casino,
    lotto,
    sport,
    user,
    text,
    wallet,
    click,
    network
}

export default Icons;
