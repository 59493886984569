import {Link} from "react-router-dom";
import Icons from "./images/Icons";
import APP from "../common/app";
import {useDispatch, useSelector} from "react-redux";
import {footerActiveMenu} from "../features/reducers/global";

const Footer = () => {

    /*Store*/
    const active = useSelector(footerActiveMenu)


    return (
        <footer className="footer">
            <div className="footer-inner">
                <div className="row align-items-center">
                    <div className={`col text-center ${active === `slot` && `active`}`}>
                        <Link to={`/slot`}>
                            <div className="mb-0">
                                <img src={Icons.slot} alt=""/>
                            </div>
                            สล็อต
                        </Link>
                    </div>
                    <div className={`col text-center ${active === `casino` && `active`}`}>
                        <Link to={`/casino`}>
                            <div className="mb-0">
                                <img src={Icons.casino} alt=""/>
                            </div>
                            คาสิโน
                        </Link>
                    </div>
                    <div className="col wallet text-center">
                        <Link to={`/dashboard`} className="wallet-icon">
                            <div className="text-center">
                                <div className="mb-1"><img src={APP.logoLight} alt="logo-light"/></div>
                                เป๋าตังค์
                            </div>
                        </Link>
                    </div>
                    <div className={`col text-center ${active === `lotto` && `active`}`}>
                        <Link to={`/lotto`}>
                            <div className="mb-0">
                                <img src={Icons.lotto} alt=""/>
                            </div>
                            หวย
                        </Link>
                    </div>
                    <div className={`col text-center ${active === `sport` && `active`}`}>
                        <Link to={`/sport`}>
                            <div className="mb-0">
                                <img src={Icons.sport} alt=""/>
                            </div>
                            กีฬา
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;
