import {createSlice} from "@reduxjs/toolkit";
import {
    UserAgentBankThunk, UserBalanceThunk, UserBetHistoryThunk,
    UserCheckAffSettingThunk,
    UserChildsThunk, UserOTPRequestThunk, UserOTPVerifyThunk,
    UserProfileThunk, UserPromotionThunk,
    UserSignInThunk, UserTopupHistoryThunk
} from "./userThunk";

const userSlice = createSlice({
    name: `user`,
    initialState: {
        profile: null,
        affiliate: null,
        otp: null,
        isAuthenticate: false,
        banks: [
            {name: "ธนาคารกสิกรไทย", code: "KBANK"},
            {name: "ธนาคารกรุงเทพ", code: "BBL"},
            {name: "ธนาคารกรุงไทย", code: "KTB"},
            {name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", code: "BAAC"},
            {name: "ธนาคารทหารไทยธนชาต", code: "TTB"},
            {name: "ธนาคารไอซีบีซี (ไทย)", code: "ICBC"},
            {name: "ธนาคารไทยเครดิตเพื่อรายย่อย", code: "TCD"},
            {name: "ธนาคารซิตี้แบงก์", code: "CITI"},
            {name: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)", code: "SCBT"},
            {name: "ธนาคารซีไอเอ็มบีไทย", code: "CIMB"},
            {name: "ธนาคารยูโอบี", code: "UOBT"},
            {name: "ธนาคารกรุงศรีอยุธยา", code: "BAY"},
            {name: "ธนาคารออมสิน", code: "GSB"},
            {name: "ธนาคารเอชเอสบีซี ประเทศไทย", code: "HSBC"},
            {name: "ธนาคารมิซูโฮ คอร์ปอเรต", code: "MIZUHO"},
            {name: "ธนาคารอาคารสงเคราะห์", code: "GHB"},
            {name: "ธนาคารแลนด์ แอนด์ เฮ้าส์", code: "LHBANK"},
            {name: "ธนาคารทิสโก้", code: "TISCO"},
            {name: "ธนาคารเกียรตินาคิน", code: "KKP"},
            {name: "ธนาคารไทยพาณิชย์", code: "SCB"},
            {name: "ธนาคารอิสลามแห่งประเทศไทย", code: "IBANK"},
        ],
        childrens: {
            count: 0,
            users: [],
        },
        agentBank: null,
        betHistory: {
            total: 0,
            data: [],
        },
        topupHistory: {
            total: 0,
            data: [],
        },
        balance: 0,
        promotions: [],
    },
    reducers: {
        SetProfile: (state, {payload}) => {
            const bank = state.banks.find(item => item.code === payload?.bankName)
            return {...state, profile: {...payload, bankDetail: {...bank, code: bank?.code.toLowerCase()}}}
        },
        SetAuthenticate: (state, {payload}) => {
            return {...state, isAuthenticate: payload}
        }
    },
    extraReducers: {
        [UserSignInThunk.fulfilled]: (state, {payload}) => {
            localStorage.setItem("accessToken", payload?.accessToken ?? ``)
            localStorage.setItem("refreshToken", payload?.refreshToken ?? ``);
            return {...state, isAuthenticate: true}
        },
        [UserOTPVerifyThunk.fulfilled]: (state, {payload}) => {
            const token = payload?.accessToken ?? ``
            if (token !== `` && typeof token !== `undefined`) {
                localStorage.setItem("accessToken", payload?.accessToken ?? ``)
                localStorage.setItem("refreshToken", payload?.refreshToken ?? ``);
                return {...state, isAuthenticate: true}
            }
        },
        [UserOTPRequestThunk.fulfilled]: (state, {payload}) => {
            return {...state, otp: payload}
        },
        [UserChildsThunk.fulfilled]: (state, {payload}) => {
            return {...state, childrens: payload}
        },
        [UserBalanceThunk.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    balance: payload
                }
            }
        },
        [UserProfileThunk.fulfilled]: (state, {payload}) => {
            const bank = state.banks.find(item => item.code === payload?.bankAccount?.bank)
            let bankDetail = {
                name: ``, code: ``, codeUpper: ``
            }
            if (typeof bank?.code !== `undefined`) {
                bankDetail = {
                    ...bank,
                    code: (typeof bank?.code !== `undefined` ? bank?.code?.toLowerCase() : ``),
                    codeUpper: bank?.code ?? ''
                }
            }
            return {
                ...state,
                profile: {
                    ...payload,
                    bankDetail: bankDetail
                }
            }
        },
        [UserCheckAffSettingThunk.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                affiliate: payload
            }
        },
        [UserAgentBankThunk.fulfilled]: (state, {payload}) => {
            const bank = state.banks.find(item => item.code === payload?.bank)
            return {
                ...state,
                agentBank: {
                    ...payload,
                    bankDetail: bank
                }
            }
        },
        [UserBetHistoryThunk.fulfilled]: (state, {payload}) => {
            return {
                ...state, betHistory: payload
            }
        },
        [UserTopupHistoryThunk.fulfilled]: (state, {payload}) => {
            return {
                ...state, topupHistory: payload
            }
        },
        [UserPromotionThunk.fulfilled]: (state, {payload}) => {
            return {
                ...state, promotions: payload
            }
        },
    }

})
export const isAuthenticate = (state) => state.user.isAuthenticate
export const getProfile = (state) => state.user.profile
export const getBanks = (state) => state.user.banks
export const getAgentBank = (state) => state.user.agentBank
export const getBetHistory = (state) => state.user.betHistory
export const getTopupHistory = (state) => state.user.topupHistory
export const getChildrens = (state) => state.user.childrens
export const getAffiliate = (state) => state.user.affiliate
export const getPromotions = (state) => state.user.promotions
export const getOtp = (state) => state.user.otp
export const {SetProfile, SetAuthenticate} = userSlice.actions

export default userSlice.reducer
