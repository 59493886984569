import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {UserBetHistory} from "../../../common/api/userApi";
import {GameGetLists, GameLunch} from "../../../common/api/gameApi";
import {SetOpenGame} from "./gameSlice";


export const GameListsThunk = createAsyncThunk(
    'game/GameListsThunk',
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, GameGetLists(filter), false, false)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)

export const GameListsRecommendThunk = createAsyncThunk(
    'game/GameListsRecommendThunk',
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, GameGetLists(filter), false, false)
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)

export const GameLunchThunk = createAsyncThunk(
    'game/GameLunchThunk',
    async ({id, code, mobile}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, GameLunch(id, code, mobile), false, true)
            if (response.status === 200) {
                if (id === `PGSOFT` || id === `SLOTXO`) {
                    if (mobile) {
                        window.location.href = response?.data?.url
                        return;
                    }
                }
                baseThunk.dispatch(SetOpenGame(response?.data?.url))
                return response.data
            }
        } catch (e) {
        }
    }
)
