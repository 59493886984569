import {Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {processStatus, SetProcessStatus} from "../features/reducers/global";

/*CSS*/
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";


import Footer from "./Footer";
import {useEffect} from "react";
import {UserBalanceThunk, UserProfileThunk} from "../features/reducers/user/userThunk";
import {getProfile, isAuthenticate} from "../features/reducers/user/userSlice";
import {log} from "../common/functions/usage";
import {getOpenGame, SetOpenGame} from "../features/reducers/game/gameSlice";
import {FaTimes} from "react-icons/fa";


const MainLayout = () => {

    /*Store*/
    const dispatch = useDispatch();
    const profile = useSelector(getProfile)
    const openGame = useSelector(getOpenGame)

    /*UseEffect*/
    useEffect(() => {
        if (localStorage.getItem("accessToken") !== null && profile === null) {
            dispatch(UserProfileThunk())
        } else {
            if (localStorage.getItem("accessToken") === null || localStorage.getItem("accessToken") === ``) {
                window.location.href = `/login`
            }
        }
    }, [dispatch])

    useEffect(() => {
        log(profile)
    }, [profile])


    const handleClosePopup = () => {
        dispatch(SetOpenGame(null))
        dispatch(UserBalanceThunk())
    }


    return (
        <>
            <div className="container">
                <div className="main-app">
                    <div className="content-outlet">
                        <Outlet/>
                    </div>
                    <Footer/>
                </div>
            </div>
            {openGame !== null &&
                <div className="open-game-popup-frm">
                    <button className="close-game" onClick={() => handleClosePopup()}><FaTimes/></button>
                    <iframe src={openGame} frameBorder="0"></iframe>
                </div>
            }
        </>

    )
}

export default MainLayout;
