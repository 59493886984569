export const imageSrc = (src) => {
    return src
    let url = src
    if (typeof url !== `undefined` && url != ``) {
        return `${process.env.NEXT_PUBLIC_APP_API_BASE}/${url}`
    }
    return ``
}


export const log = (msg) => {
    if (process.env.REACT_APP_DEBUG) {
        console.log(msg)
    }
}
