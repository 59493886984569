import Logos from "../components/images/Logo";

const phenix295 = {
    app_name: `Phenix295`,
    prefix: `PHEN`,
    baseURL: `https://api.phenix295.com`,
    baseURLBE: `https://office.phenix295.com/api/`,
    enpoint: ``,
    logo: Logos.phenix.normal,
    logoLight: Logos.phenix.light,
    cover: Logos.phenix.cover,
    cssPrefix: `phenix295`,
    css: `/assets/css/phenix295.css`,
    lineQrCode: Logos.phenix.qrcode,
    lineId: `@phenix295`,
    lineUrl: `https://lin.ee/ZiHLVA6`,
}

const ufo98 = {
    app_name: `UFO98BET`,
    prefix: `UFO8`,
    baseURL: `https://api.phenix295.com`,
    baseURLBE: `https://office.phenix295.com/api/`,
    enpoint: ``,
    logo: Logos.ufo98.normal,
    logoLight: Logos.ufo98.light,
    cover: Logos.ufo98.cover,
    cssPrefix: `ufo98`,
    css: `/assets/css/ufo98.css`,
    lineQrCode: Logos.ufo98.qrcode,
    lineId: `@ufo98`,
    lineUrl: `https://lin.ee/ZiHLVA6`,
}

const vipaclub = {
    app_name: `VIPACLUB`,
    prefix: `PHEN`,
    baseURL: `https://api.phenix295.com`,
    baseURLBE: `https://office.phenix295.com/api/`,
    enpoint: ``,
    logo: Logos.vipaclub.normal,
    logoLight: Logos.vipaclub.light,
    cover: Logos.vipaclub.cover,
    cssPrefix: `vipaclub`,
    css: `/assets/css/vipaclub.css`,
    lineQrCode: Logos.vipaclub.qrcode,
    lineId: `@vipaclub`,
    lineUrl: `https://lin.ee/ZiHLVA6`,
}


const APP = phenix295
export default APP;
