import pg from "../../assets/images/games/pgslot.png";
import pretty from "../../assets/images/games/pretty.png";
import live22 from "../../assets/images/games/live22.png";
import slotxo from "../../assets/images/games/slotxo.png";
import sexy from "../../assets/images/games/sexy.png";
import dream from "../../assets/images/games/dream.png";
import ufa from "../../assets/images/games/ufa.png";
import lsm from "../../assets/images/games/lsm.png";
import muaystep from "../../assets/images/games/muaystep.png";
import sa from "../../assets/images/games/sa.png";
import soon from "../../assets/images/games/soon.png";


const GameBrands = {
    pg, lsm, ufa, sexy, muaystep, dream, slotxo, live22, pretty, soon, sa
}

export default GameBrands;
