import * as React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import MainLayout from "./components/MainLayout";
import {ToastContainer} from "react-toastify";

/*CSS*/
import 'react-toastify/dist/ReactToastify.css';
import {useEffect, useState} from "react";
import APP from "./common/app";
import {useDispatch, useSelector} from "react-redux";
import {processStatus, SetProcessStatus} from "./features/reducers/global";
import {HiOutlineCheckCircle} from "react-icons/hi";
import {IoIosWarning} from "react-icons/io";
import {MdErrorOutline} from "react-icons/md";


/* Lazy load components */
const Dashboard = React.lazy(() => import("./pages/Dashboard.js"));
const Games = React.lazy(() => import("./pages/Games"));
const Deposit = React.lazy(() => import("./pages/Deposit"));
const Withdraw = React.lazy(() => import("./pages/Withdraw"));
const Affiliate = React.lazy(() => import("./pages/Affiliate"));
const Banner = React.lazy(() => import("./pages/Banner"));
const HistoryTopup = React.lazy(() => import("./pages/HistoryTopup"));
const HistoryBet = React.lazy(() => import("./pages/HistoryBet"));
const Account = React.lazy(() => import("./pages/Account"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Password = React.lazy(() => import("./pages/Password"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Login = React.lazy(() => import("./pages/Login"));
const GameLists = React.lazy(() => import("./pages/GameLists"));
const Register = React.lazy(() => import("./pages/Register"));
const Otp = React.lazy(() => import("./pages/Otp"));


function App() {

    /*Store*/
    const dispatch = useDispatch();
    const alert = useSelector(processStatus)

    /*State*/
    const [cssPath, setCssPath] = useState(APP.css);

    /*Use Effext*/
    useEffect(() => {
        document.body.className = APP.cssPrefix;
    }, [])

    useEffect(() => {
        var head = document.head;
        var link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = APP.css;
        head.appendChild(link);
        return () => {
            head.removeChild(link);
        }
    }, [APP.css]);

    useEffect(() => {
        // console.log(alert?.status)
        if (typeof alert?.status !== `undefined` && alert?.status !== null) {
            document.body.classList.add(`loading`)
        } else {
            // console.log(`remove`)
            document.body.classList.remove(`loading`)
        }
    }, [alert?.status])

    /*Handle*/
    const handleCloseStatusOverlay = () => {
        dispatch(SetProcessStatus(null))
    }


    return (<>

        <Router>
            <React.Suspense>
                <Routes>
                    <Route exact={true} path="/login" element={<Login/>}/>
                    <Route exact={true} path="/register" element={<Register/>}/>
                    <Route exact={true} path="/register/otp" element={<Otp/>}/>
                    <Route path="/" element={<MainLayout/>}>
                        <Route exact={true} path="/" element={<Dashboard/>}/>
                        <Route exact={true} path="/dashboard" element={<Dashboard/>}/>

                        <Route exact={true} path="/slot" element={<Games provider="slot" title={`สล็อตออนไลน์`}/>}/>
                        <Route exact={true} path="/casino" element={
                            <Games provider="casino" title={`คาสิโนออนไลน์`}/>}/>
                        <Route exact={true} path="/lotto" element={<Games provider="lotto" title={`หวยออนไลน์`}/>}/>
                        <Route exact={true} path="/sport" element={
                            <Games provider="sport" title={`กีฬาออนไลน์`}/>}/>

                        <Route path="/game/:game/:provider" element={<GameLists/>}/>


                        <Route exact={true} path="/deposit" element={<Deposit/>}/>
                        <Route exact={true} path="/withdraw" element={<Withdraw/>}/>
                        <Route exact={true} path="/affiliate" element={<Affiliate/>}/>
                        <Route exact={true} path="/banner" element={<Banner/>}/>

                        <Route path="/history-topup" element={<HistoryTopup/>}/>
                        <Route path="/history-bet" element={<HistoryBet/>}/>


                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/account" element={<Account/>}/>
                        <Route path="/password" element={<Password/>}/>
                        <Route path="/contact" element={<Contact/>}/>

                    </Route>
                </Routes>
            </React.Suspense>
        </Router>
        <ToastContainer/>
        {/*<link rel="stylesheet" type="text/css" href={cssPath}/>*/}

        {alert != null && (
            <>
                <div className={`status-overlay status-${alert?.status}`}>
                    <div className={`text-center`}>
                        <div className={`${alert?.status} text-center mb-5`}>
                            {alert?.status === `success` && (
                                <>
                                    <div className="mb-2"><HiOutlineCheckCircle/></div>
                                    <div>{alert?.message ?? `บันทึกข้อมูลเรียบร้อย`}</div>
                                </>
                            )}
                            {alert?.status === `warning` && (
                                <>
                                    <div className="mb-2"><IoIosWarning/></div>
                                    <div>{alert?.message ?? `กรุณากรอกข้อมูล`}</div>
                                </>
                            )}
                            {alert?.status === `error` && (
                                <>
                                    <div className="mb-2"><MdErrorOutline/></div>
                                    <div>{alert?.message ?? `ผิดพลาด ดำเนินการไม่สำเร็จ`}</div>
                                </>
                            )}
                            {alert?.status === `loading` && (
                                <>
                                    <div className="mb-2 loading-screen">
                                        <div className="lds-ring">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div className="loading-screen-title">Loading...</div>
                                    </div>
                                </>
                            )}
                        </div>
                        {alert?.status !== `loading` &&
                            <button className={`btn btn-submit btn-${alert?.status} px-5`} onClick={() => handleCloseStatusOverlay()}>ปิดหน้าต่าง</button>
                        }
                    </div>
                </div>

            </>
        )}



    </>);
}

export default App;
