import httpClient, {httpObjQueryString} from "../config/httpClient";
import APP from "../app";

const enpoint = ``

export const UserSignIn = (username, password, prefix) => {
    // new - /user/v2/signin
    // old - /user/login

    return httpClient.post(`${enpoint}/user/v2/signin`, {
        'username': prefix + username,
        'password': password,
        'prefix': prefix
    })
}

export const UserCheckPhone = (phone) => {
    return httpClient.get(`${enpoint}/user/v2/phonecheck/${APP.prefix}/${phone}`)
}

export const UserCheckRef = (ref) => {
    return httpClient.get(`${enpoint}/user/v2/${APP.prefix}/checkref?ref=${ref}`)
}

export const UserOTPRequest = (username, password, phone, prefix, promotion = false) => {
    const data = {
        'phone': phone,
        'prefix': prefix,
        'username': username,
        'password': password,
        'ref': '',
        'promotion': promotion,
    }
    const ref = localStorage.getItem("ref")
    if (ref != null && ref != "") {
        data.ref = ref
    }
    return httpClient.post(`/user/v2/otp`, data)
    // return httpClient.post(`/otp`, data)
}

export const UserOTPVerify = (phone, code, otp, prefix, username, password, promotion = false) => {
    let data = {
        'phone': phone,
        'code': code,
        'otp': otp,
        'prefix': prefix,
        'username': username,
        'password': password,
        'ref': '',
        'promotion': (promotion === "true" || promotion === true) ? true : false,
    }
    const ref = localStorage.getItem("ref")
    if (ref != null && ref != "") {
        data.ref = ref
    }
    return httpClient.post(`/user/v2/otp/verify`, data)
}

export const UserProfile = () => {
    // new /user/v2/me
    // old /user/me
    return httpClient.get(`${enpoint}/user/v2/me`)
}

export const UserBalance = () => {
    // new /user/v2/me
    // old /user/me
    return httpClient.get(`${enpoint}/user/v2/balance`)
}

export const UserUpdateProfile = (data) => {
    return httpClient.patch(`${enpoint}/user/me`, data)
}

export const UserUpdateBankAccountProfile = (data) => {
    return httpClient.post(`${enpoint}/user/v2/bankaccount`, data)
}

export const UserChangePassword = (data) => {
    return httpClient.post(`${enpoint}/user/v2/changepwd`, data)
}

export const UserAgentBank = () => {
    // return httpClient.get(`${enpoint}/user/banking`)
    return httpClient.get(`${enpoint}/banking/v2/bankaccount`)
}

export const UserWithdraw = (data) => {
    return httpClient.post(`${enpoint}/transaction/v2/withdraw`, data)
}

export const UserWithdrawAff = (data) => {
    return httpClient.put(`${enpoint}/aff/v2/withdraw`, data)
}

export const UserChilds = (prefix, page, pageSize = 15) => {
    // return httpClient.get(`${enpoint}/user/affiliate/lists?page=${page}&pageSize=${pageSize}`)
    return httpClient.get(`${enpoint}/user/v2/children?page=${page}&pageSize=${pageSize}`)
}

export const UserTogglePromotion = () => {
    return httpClient.post(`${enpoint}/user/v2/togglepromo`)
}

export const UserCheckAffSetting = (prefix, username) => {
    return httpClient.get(`${enpoint}/aff/v2`)
}

export const UserSetAffSetting = (select, prefix, username) => {
    return httpClient.post(`${enpoint}/aff/v2`, {
        type: select,
    })
}

export const UserBetHistory = (filter) => {
    // return httpClient.get(`${enpoint}/user/gameHistory/${APP.prefix}/${filter.username}?${httpObjQueryString(filter)}`)
    let f = {
        ...filter,
        date: (`${filter.startTime}`),
        startTime: (`${filter.startTime}T23:59:00+07:00`),
        endTime: (`${filter.endTime}T00:00:00+07:00`),
        to: (`${filter.startTime}T23:59:00+07:00`),
        from: (`${filter.endTime}T00:00:00+07:00`),
    }
    return httpClient.get(`${enpoint}/report/v2/gamehistory?${httpObjQueryString(f)}`)
}

export const UserTopupHistory = (filter) => {
    // return httpClient.get(`${enpoint}/user/topUpHistory/${APP.prefix}/${filter.username}?${httpObjQueryString(filter)}`)
    let f = {
        ...filter,
        to: (`${filter.to}T23:59:00+07:00`),
        from: (`${filter.from}T00:00:00+07:00`),
        prefix: APP.prefix
    }
    return httpClient.get(`${enpoint}/user/v2/txnhistory?${httpObjQueryString(f)}`)
}
export const UserPromotion = (prefix) => {
    return httpClient.get(`${enpoint}/promotion/v2/${prefix}`)
}
